<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Estadísticas de asesores</v-toolbar-title
               >
               <div style="width: 150px">
                  <v-autocomplete
                     v-model="selectCicloPlanesPago"
                     class="mt-7 ml-3"
                     label="Ciclo"
                     :items="cmbCiclosPlanesPago"
                  >
                  </v-autocomplete>
               </div>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-7 ml-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
            </v-toolbar>
            <!-- DATATABLE estadisticas -->
            <v-data-table
               :headers="headers"
               :items="estadisticas"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #body.append="">
                  <!-- Linea de totales -->
                  <tr v-if="$vuetify.breakpoint.smAndUp">
                     <td></td>
                     <td class="text-center text-body-1 font-weight-medium">
                        Totales
                     </td>
                     <td class="text-center text-body-1 font-weight-medium">
                        {{ totales.pendiente }}
                     </td>
                     <td class="text-center text-body-1 font-weight-medium">
                        {{ totales.enProceso }}
                     </td>
                     <td class="text-center text-body-1 font-weight-medium">
                        {{ totales.preAprobado }}
                     </td>
                     <td class="text-center text-body-1 font-weight-medium">
                        {{ totales.aprobado }}
                     </td>
                     <td class="text-center text-body-1 font-weight-medium">
                        {{ totales.denegado }}
                     </td>
                     <td class="text-center text-body-1 font-weight-medium">
                        {{ totales.anulado }}
                     </td>
                     <td class="text-center text-body-1 font-weight-medium">
                        {{ totales.total }}
                     </td>
                  </tr>
               </template>
               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="estadisticas.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'

export default {
   name: 'Estadisticas',
   mixins: [formatters],
   data: () => ({
      // Variables
      dialogEstadistica: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Nombre', value: 'fullName', class: 'titulo' },
         { text: 'Usuario', value: 'user', class: 'titulo' },
         {
            text: 'Solicitudes pendientes',
            value: 'pendiente',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Solicitudes en proceso',
            value: 'enProceso',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Solicitudes en pre-aprobadas',
            value: 'preAprobado',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Solicitudes aprobadas',
            value: 'aprobado',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Solicitudes denegadas',
            value: 'denegado',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Solicitudes anuladas',
            value: 'anulado',
            align: 'center',
            class: 'titulo'
         },
         { text: 'Total', value: 'total', align: 'center', class: 'titulo' }
      ],

      dialogReportes: false,
      // Variables de formularios
      cmbCiclosPlanesPago: [],
      selectCicloPlanesPago: '',
      search: '',
      pagination: {},
      result: [],
      estadisticas: [],
      totales: {
         pendiente: 0,
         enProceso: 0,
         aprobado: 0,
         denegado: 0,
         anulado: 0,
         preAprobado: 0,
         total: 0
      }
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nueva Estadistica'
            : 'Editar Estadistica'
      }
   },
   watch: {
      selectCicloPlanesPago() {
         this.listarEstadisticas()
      }
   },
   created() {
      // Validando acceso al componente
      this.listarEstadisticas()
      this.listarComboboxes()
   },
   methods: {
      listarComboboxes() {
         axios
            .get('api/FormularioPlanPago/ciclosDisponibles')
            .then((response) => {
               this.cmbCiclosPlanesPago = response.data
               this.selectCicloPlanesPago =
                  response.data[response.data.length - 1]
            })
            .catch(() => {
               //console.log(error)
            })
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarEstadisticas() {
         if (!this.selectCicloPlanesPago) return
         // Obteniendo estadisticas
         axios
            .get(
               'api/FormularioPlanPago/Reporte?cicloDeAplicacion=' +
                  this.selectCicloPlanesPago
            )
            .then((response) => {
               this.estadisticas = response.data.solicitudes
               this.getTotales()
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las estadisticas en el datatable se puede resetear
      reset() {
         this.listarEstadisticas()
      },
      getTotales() {
         var total = {
            pendiente: 0,
            enProceso: 0,
            aprobado: 0,
            denegado: 0,
            anulado: 0,
            preAprobado: 0,
            total: 0
         }
         this.estadisticas.forEach((el) => {
            total.pendiente += parseFloat(el.pendiente)
            total.enProceso += parseFloat(el.enProceso)
            total.aprobado += parseFloat(el.aprobado)
            total.denegado += parseFloat(el.denegado)
            total.anulado += parseFloat(el.anulado)
            total.preAprobado += parseFloat(el.preAprobado)
            total.total += parseFloat(el.total)
         })
         this.totales = total
      }
   }
}
</script>
